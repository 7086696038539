.footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 20px;
  width: 100vw; 
}

.footer-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.footer-icons a {
  color: white;
  font-size: 24px;
  transition: color 0.3s;
}

.footer-icons a:hover {
  color: #ccc;
}

.footer-location {
  margin-top: 20px;
  font-size: 16px;
}

.footer-location a {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-location a:hover {
  color: #ccc;
}
