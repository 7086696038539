/* Main dashboard container adjustments for all screen sizes */
.owner-dashboard .admin-dashboard-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #2C2C2C;
  color: #F5F5DC;
  min-height: 100vh;
  flex-wrap: wrap;
  position: relative;
  width: 100%; /* Adjust to ensure full width on all screen sizes */
  box-sizing: border-box;
}

/* Pop-up overlay and box styling */
.owner-dashboard .popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.owner-dashboard .popup {
  background: #2C2C2C;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.7);
  width: 80%;
  max-width: 600px;
  text-align: left;
}

/* Popup content */
.owner-dashboard .popup h2 {
  font-size: 2em;
  color: #F5F5DC;
  margin-bottom: 20px;
}

.owner-dashboard .popup p {
  font-size: 1.2em;
  color: #F5F5DC;
  margin-bottom: 20px;
  max-height: 300px;
  overflow-y: auto;
  padding: 10px;
  line-height: 1.6;
  text-align: justify;
}

.owner-dashboard .popup .agree-section {
  margin-bottom: 20px;
}

.owner-dashboard .popup .buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.owner-dashboard .popup .continue-button,
.owner-dashboard .popup .close-button {
  padding: 10px 20px;
  font-size: 1.2em;
  color: #F5F5DC;
  background-color: #556B2F;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.owner-dashboard .popup .continue-button:disabled {
  background-color: #888888;
}

.owner-dashboard .popup .continue-button:hover:enabled,
.owner-dashboard .popup .close-button:hover {
  background-color: #6B8E23;
}

/* Left and right sections */
.owner-dashboard .left-section {
  width: 65%;
  min-width: 300px;
  margin-bottom: 20px;
  background-color: #444444;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 5;
}

.owner-dashboard .right-section {
  width: 30%;
  background-color: #444444;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  min-width: 250px;
  position: relative;
  z-index: 1;
}

/* Bookings container */
.owner-dashboard .bookings-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 20px;
  flex-wrap: wrap;
  position: relative;
  z-index: 5;
}

/* Simulator columns */
.owner-dashboard .sim-column {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  width: 100%;
  box-sizing: border-box; /* Ensure proper width sizing */
}

/* Simulator sections */
.owner-dashboard .simulator-section {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Spacing between time slots inside each section */
}

/* Time slots styling */
.owner-dashboard .time-slot {
  background-color: #6B8E23;
  color: #F5F5DC;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  width: 100%;
  box-sizing: border-box;
}

.owner-dashboard .unavailable {
  background-color: #D9534F;
  color: #F5F5DC;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  font-size: 1.1em;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
}
.owner-dashboard .booked-slot {
  background-color: #D9534F; /* No background */
  padding: 0;
  border: none;
  box-shadow: none;
}
.owner-dashboard .selected {
  border: 2px solid #F5F5DC;
  box-shadow: 0 0 10px #F5F5DC;
  transition: all 0.3s ease;
}

/* Empty bookings styling */
.owner-dashboard .simulator-section:empty:before {
  content: "No bookings available";
  color: #F5F5DC;
  background-color: #444444;
  padding: 15px;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 10px;
}

/* Simulator titles */
.owner-dashboard .simulator-titles {
  margin-bottom: 15px;
  text-align: center;
  font-size: 1.6em;
  color: #F5F5DC;
}

/* Available time slots */
.owner-dashboard .available {
  background-color: #6B8E23;
  color: #F5F5DC;
  padding: 15px;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  font-size: 1.1em;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
}
/* Custom inputs and date picker */
.owner-dashboard .custom-input,
.owner-dashboard .custom-datepicker {
  background-color: #444444;
  color: #F5F5DC;
  padding: 15px;
  font-size: 1.2em;
  border: none;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Create button and terms button */
.owner-dashboard .create-btn,
.owner-dashboard .terms-btn {
  background-color: #6B8E23;
  color: #F5F5DC;
  padding: 15px;
  font-size: 1.2em;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.owner-dashboard .create-btn:hover,
.owner-dashboard .terms-btn:hover {
  background-color: #4B5D2A;
}

/* Calendar container */
.owner-dashboard .calendar-container {
  margin-bottom: 30px;
  width: 100%;
}

.owner-dashboard .react-calendar {
  background-color: #444444;
  color: #F5F5DC;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 100%; /* Ensure it fits the container */
}



.owner-dashboard .react-calendar__tile {
  background-color: #444444;
  color: #F5F5DC;
  
  border-radius: 5px;
}

.owner-dashboard .react-calendar__tile--active {
  background-color: #6B8E23 !important;
  color: #F5F5DC !important;
}

.owner-dashboard .react-calendar__tile--now {
  background-color: rgba(107, 142, 35, 0.5) !important;
  color: #F5F5DC !important;
}

.owner-dashboard .react-calendar__tile--hover {
  background-color: #556B2F !important;
  color: #F5F5DC !important;
}


/* Reservation card styling */
.owner-dashboard .reservation-card {
  background-color: #9b2e2e; /* Dark red for booked slots */
  color: #F5F5DC;
  padding: 15px;
  border-radius: 8px;
  border: 2px solid #9b2e2e;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
  word-wrap: break-word;
}

.owner-dashboard .reservation-card h4,
.owner-dashboard .reservation-card p {
  margin: 0;
  padding-bottom: 5px;
  font-size: 1em;
  word-wrap: break-word;
}

.owner-dashboard .reservation-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
  z-index: 10; /* Ensure it's above other elements */
  position: relative;
}

/* Buttons for edit and delete */
.owner-dashboard .reservation-buttons button {
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease;
  z-index: 100000;
  pointer-events: auto; /* Ensure buttons are clickable */
 
}

.owner-dashboard .edit-btn {
  background-color: #6B8E23;
  color: #F5F5DC;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.owner-dashboard .delete-btn {
  background-color: #ff0800;
  color: #F5F5DC;
  padding: 10px 20px;
  border-radius: 5px;
  border: solid black 2px;
  cursor: pointer;
}
.past-time {
  color: red;
  cursor: not-allowed;
}

.owner-dashboard .reservation-buttons button:hover {
  transform: scale(1.05);
}
.owner-dashboard select {
  background-color: #444444;
  color: #F5F5DC;
  padding: 15px;
  font-size: 1.2em;
  border: none;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Form container */
.owner-dashboard .form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
}

/* Logout section */
.owner-dashboard .logout-section {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.owner-dashboard .logout-btn {
  padding: 10px 20px;
  background-color: #D9534F;
  color: #F5F5DC;
  font-size: 1.2em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.owner-dashboard .logout-btn:hover {
  background-color: #C9302C;
}

/* Responsive adjustments for smaller screens */
/* Responsive adjustments for smaller screens */
@media screen and (max-width: 992px) {
  .owner-dashboard .admin-dashboard-container {
    flex-direction: column; /* Stack sections vertically */
  }

  .owner-dashboard .left-section,
  .owner-dashboard .right-section {
    width: 100%; /* Full width for both sections on small screens */
    padding: 15px;
  }

  .owner-dashboard .sim-column {
    grid-template-columns: 1fr; /* Single column for smaller screens */
  }

  .owner-dashboard .reservation-card {
    width: 100%;
    padding: 10px;
  }

  .owner-dashboard .time-slot {
    padding: 10px;
  }
}

@media screen and (max-width: 768px) {
  .owner-dashboard .reservation-card {
    padding: 8px;
    font-size: 0.9em; /* Slightly smaller text for mobile */
  }

  .owner-dashboard .time-slot {
    padding: 8px;
  }

  /* Adjust calendar size */
  .owner-dashboard .react-calendar {
    padding: 15px;
    max-width: 100%;
  }

  /* Adjust header and title fonts */
  .owner-dashboard h1 {
    font-size: 1.5em;
  }

  .owner-dashboard .simulator-titles {
    font-size: 1.4em;
  }
}

/* For very small screens (phones) */
@media screen and (max-width: 576px) {
  .owner-dashboard .reservation-card {
    font-size: 0.85em; /* Adjust text size for mobile */
    padding: 6px;
  }

  .owner-dashboard .reservation-buttons button {
    font-size: 0.8em;
    padding: 8px 12px;
  }

  .owner-dashboard .time-slot {
    padding: 6px;
    font-size: 0.75em;
  }

  .owner-dashboard .react-calendar {
    padding: 10px;
    max-width: 100%;
  }
}