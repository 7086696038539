@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');

/* Body */
body.lessons-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  font-family: 'Montserrat', sans-serif;
  background-color: #444444;
  color: #F5F5DC;
  overflow-y: scroll;
}

/* Video styling */
.header video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Header with centered video */
.header {
  position: relative;
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.header-video {
  width: 100%;
  height: auto;
  object-fit: cover;
  filter: brightness(60%);
  border: 2px solid #2C2C2C;
}

/* Title styling */
.title {
  text-align: center;
  font-size: 4em;
  margin: 20px auto;
  color: #556B2F;
  padding: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  width: fit-content;
  margin-bottom: 30px;
  color: #F5F5DC;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  border-bottom: 2px solid #556B2F; /* Shadow for contrast */
}

/* Container */
.lessons-page .container {
  width: 90%;
  max-width: 1200px;
  margin: 50px auto;
  padding: 20px;
  background-color: #444444;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  text-align: center;
  animation: fadeIn 1.5s;
}

/* Intro Section */
.lessons-page .intro {
  padding: 20px;
  background-color: #2C2C2C;
  color: #F5F5DC;
  border-radius: 15px;
  margin-bottom: 20px;
}

/* Lesson Section */
.lessons-page .lesson-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  margin-top: 20px;
  align-items: stretch; /* Ensure the cards stretch evenly */
}

/* Cards */
.lessons-page .card {
  background-color: #3B3B3B;
  padding: 40px; /* Increased padding for better spacing */
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around; /* Ensures content is spaced evenly */
  height: auto; /* Removed fixed height */
  min-height: 300px; /* Ensures consistent card size without too much empty space */
  max-width: 400px; /* Limit the width of the cards for better layout */
  margin: 0 auto; /* Center the cards horizontally */
}

/* Card Title */
.lessons-page .card h2 {
  font-size: 1.6em; /* Reduced the font size slightly */
  color: #6B8E23;
  margin-bottom: 15px;
}

/* Card Description */
.lessons-page .card p {
  font-size: 1.1em; /* Adjusted the font size for better readability */
  line-height: 1.6; /* Increased line height for better spacing */
  color: #F5F5DC;
}

/* Book Now Button */
.book-now-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.book-now-button {
  background-color: #6B8E23;
  color: #fff;
  border: none;
  padding: 15px 30px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.book-now-button:hover {
  background-color: #556B2F;
}

/* Instructor Profile Section */
.instructor-profile {
  text-align: center;
  margin-top: 40px;
  padding: 30px;
  background-color: #2C2C2C;
  border-radius: 15px;
  color: #F5F5DC;
}

.instructor-profile h2 {
  font-size: 2.5em;
  color: #6B8E23;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.profile-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.profile-picture {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #556B2F;
}

.profile-details {
  max-width: 600px;
  text-align: left;
}

.profile-details h3 {
  font-size: 1.8em;
  color: #6B8E23;
  margin-bottom: 10px;
}

.profile-details p {
  font-size: 1.1em;
  line-height: 1.6;
}


/* Footer */
.footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 20px;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .title {
    font-size: 3em;
  }

  .lessons-page .card h2 {
    font-size: 1.5em;
  }

  .lessons-page .card p {
    font-size: 1em;
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 2.5em;
  }

  .lessons-page .card h2 {
    font-size: 1.3em;
  }

  .lessons-page .card p {
    font-size: 0.9em;
  }
}

@media (max-width: 576px) {
  .title {
    font-size: 2em;
  }

  .lessons-page .card h2 {
    font-size: 1.1em;
  }

  .lessons-page .card p {
    font-size: 0.8em;
  }

  .lessons-page .container {
    width: 95%;
    margin: 20px auto;
    padding: 10px;
  }

  .lessons-page .card {
    padding: 20px;
    min-height: 280px;
  }
}
