@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Playfair+Display:wght@400;700&display=swap');

body.reservations-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Centering the content horizontally */
  min-height: 100vh;
  font-family: 'Playfair Display', serif;
  background-color: #444444;
  color: #F5F5DC;
  overflow-y: scroll;
  cursor: url('../../public/assets/images/tee.png'), auto;
}

.reservations-page .reservations-container {
  color: #F5F5DC;
  padding: 40px;
  width: 90%; /* Adjusting the width */
  max-width: 1200px; /* Setting a max-width */
  margin: 60px auto;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  background-color: #444444;
  position: relative;
  animation: fadeIn 1.5s;
  text-align: center;
  flex-grow: 1;
}
.spinner-option {
  width: 20px;
  height: 20px;
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  animation: spin 1s linear infinite;
  display: inline-block;
  color: #000000;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.form-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  max-width: 900px;
  margin: 0 auto;
}

.form-column {
  flex: 1 1 45%; /* Each column takes up approximately half of the available space */
  min-width: 220px; /* Ensure columns are responsive but maintain structure */
}

.custom-input,
.custom-datepicker {
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  border: 2px solid #556B2F;
  border-radius: 5px;
  font-size: 1.2em;
}

.form-column-full {
  width: 100%; /* Use full width for specific elements like the button or error messages */
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.reservations-page .reservations-title {
  text-align: center;
  font-size: 4em;
  margin: 20px auto;
  color: #556B2F;
  padding: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  width: fit-content;
  margin-bottom: 30px;
  color: #F5F5DC;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  border-bottom: 2px solid #556B2F;
  
}

.reservations-page .reservations-description {
  font-size: 1.5em;
  margin-bottom: 30px;
}

.reservations-page .simulator {
  background-color: #3B3B3B;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  width: 80%; /* Making the card wider */
  max-width: 600px; /* Setting a max-width */
  margin: 0 auto; /* Centering the card */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}



.reservations-page .simulator h2 {
  color: #556B2F;
  font-size: 2em;
  margin-bottom: 10px;
}

.react-datepicker-popper {
  font-size: 1.5em !important; /* Increase font size for better readability */
}

.react-datepicker__header {
  background-color: #556B2F;
  border-bottom: 1px solid #6B8E23;
  color: #F5F5DC;
  padding: 10px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: #F5F5DC;
  font-size: 1.5em !important; /* Increase font size for better readability */
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #2C2C2C;
  margin: 10px;
  font-size: 1.5em !important; /* Increase font size for better readability */
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #6B8E23;
  color: #F5F5DC;
  border-radius: 50%;
}

.react-datepicker__day:hover {
  background-color: #556B2F;
  color: #F5F5DC;
  border-radius: 50%;
}

.react-datepicker__time-container {
  border-left: 1px solid #6B8E23;
}

.react-datepicker__time-list {
  padding: 0;
  display: flex;
  flex-direction: column;
}

.react-datepicker__time-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.react-datepicker__time {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.react-datepicker__time-list-item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: 1.5em !important; /* Increase font size for better readability */
}

.react-datepicker__time-list-item:hover {
  background-color: #556B2F;
  color: #F5F5DC;
}

.react-datepicker__time-list-item--selected {
  background-color: #6B8E23;
  color: #F5F5DC;
}

.reservations-page .simulator-image {
  width: 100%;
  height: auto;
  border: #000000 solid 1.5px;
  border-radius: 10px;
  object-fit: cover;
  margin-bottom: 15px;
}


@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

/* Text and Picture Areas */
.reservations-page .info-section {
  margin: 40px 0;
  text-align: left;
  padding: 20px;
  background-color: #2C2C2C;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.reservations-page .info-section h3 {
  font-size: 2.5em;
  color: #F5F5DC;
  margin-bottom: 15px;
}

.reservations-page .info-section p {
  font-size: 1.5em;
  color: #F5F5DC;
}

.reservations-page .image-section {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

.reservations-page .image-section img {
  width: 400px;
  height: 300px;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.reservations-page .image-section img:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.8);
}

/* Pricing Table */
.reservations-page .pricing-table {
  margin: 40px 0;
  text-align: left;
  padding: 20px;
  background-color: #2C2C2C;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.reservations-page .pricing-table h3 {
  font-size: 2.5em;
  color: #F5F5DC;
  margin-bottom: 15px;
}

.reservations-page .pricing-table table {
  width: 100%;
  border-collapse: collapse;
  color: #F5F5DC;
}

.reservations-page .pricing-table table, th, td {
  border: 1px solid #556B2F;
}

.reservations-page .pricing-table th, .reservations-page .pricing-table td {
  padding: 15px;
  text-align: left;
}

.reservations-page .pricing-table th {
  background-color: #3B3B3B;
}

.reservations-page .pricing-table td {
  background-color: #2C2C2C;
}

/* Promotions Section */
.reservations-page .promotions-section {
  margin: 40px 0;
  text-align: left;
  padding: 20px;
  background-color: #2C2C2C;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.reservations-page .promotions-section h3 {
  font-size: 2.5em;
  color: #F5F5DC;
  margin-bottom: 15px;
}

.reservations-page .promotions-section p {
  font-size: 1.5em;
  color: #F5F5DC;
}

/* Checkout Button */
.reservations-page .checkout-button {
  margin: 20px auto;
  padding: 15px 30px;
  font-size: 1.5em;
  color: #F5F5DC;
  background-color: #556B2F;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-family: 'Playfair Display', serif;
}

.reservations-page .checkout-button:hover {
  background-color: #6B8E23;
  transform: scale(1.05);
}

/* Popup Styles */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  background: #2C2C2C;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.7);
  width: 80%;
  max-width: 600px;
  text-align: left;
}

.popup h2 {
  font-size: 2em;
  color: #F5F5DC;
  margin-bottom: 20px;
}

.popup p {
  font-size: 1.2em;
  color: #F5F5DC;
  margin-bottom: 20px;
  max-height: 300px;
  overflow-y: auto;
  padding: 10px; /* Add padding for better readability */
  line-height: 1.6; /* Improve line height for readability */
  text-align: justify; /* Format text for better alignment */
}

.popup .agree-section {
  margin-bottom: 20px;
}

.popup .agree-section label {
  font-size: 1.2em;
  color: #F5F5DC;
}

.popup .continue-button,
.popup .close-button {
  padding: 10px 20px;
  font-size: 1.2em;
  color: #F5F5DC;
  background-color: #556B2F;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 10px; /* Add margin to separate the buttons */
}

.popup .continue-button:disabled {
  background-color: #888888;
}

.popup .continue-button:hover:enabled,
.popup .close-button:hover {
  background-color: #6B8E23;
}

.popup .buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px; /* Add gap between buttons */
}


/* Media Queries for Responsive Design */
@media (max-width: 1200px) {
  .reservations-page .reservations-title {
    font-size: 3.5em;
  }

  .reservations-page .reservations-description {
    font-size: 1.4em;
  }

  .reservations-page .simulator h2 {
    font-size: 1.8em;
  }

  .reservations-page .datepicker {
    font-size: 1.1em;
  }

  .reservations-page .info-section h3,
  .reservations-page .pricing-table h3,
  .reservations-page .promotions-section h3 {
    font-size: 2.2em;
  }

  .reservations-page .info-section p,
  .reservations-page .promotions-section p {
    font-size: 1.3em;
  }

  .reservations-page .checkout-button {
    font-size: 1.3em;
  }
}

@media (max-width: 992px) {
  .reservations-page .reservations-title {
    font-size: 3em;
  }

  .reservations-page .reservations-description {
    font-size: 1.3em;
  }

  .reservations-page .simulator h2 {
    font-size: 1.6em;
  }

  .reservations-page .datepicker {
    font-size: 1em;
  }

  .reservations-page .info-section h3,
  .reservations-page .pricing-table h3,
  .reservations-page .promotions-section h3 {
    font-size: 2em;
  }

  .reservations-page .info-section p,
  .reservations-page .promotions-section p {
    font-size: 1.2em;
  }

  .reservations-page .checkout-button {
    font-size: 1.2em;
  }

  .reservations-page .decorative-golfball {
    width: 70px;
    height: 70px;
  }
}

@media (max-width: 768px) {
  .reservations-page .reservations-title {
    font-size: 2.5em;
  }

  .reservations-page .reservations-description {
    font-size: 1.2em;
  }

  .reservations-page .simulator h2 {
    font-size: 1.4em;
  }

  .reservations-page .datepicker {
    font-size: 0.9em;
  }

  .reservations-page .info-section h3,
  .reservations-page .pricing-table h3,
  .reservations-page .promotions-section h3 {
    font-size: 1.8em;
  }

  .reservations-page .info-section p,
  .reservations-page .promotions-section p {
    font-size: 1.1em;
  }

  .reservations-page .checkout-button {
    font-size: 1.1em;
  }

  .reservations-page .decorative-golfball {
    width: 60px;
    height: 60px;
  }

  .reservations-page .image-section img {
    width: 100%;
    height: auto;
  }

  .reservations-page .pricing-table th,
  .reservations-page .pricing-table td {
    padding: 10px;
    font-size: 0.9em;
  }

  .reservations-page .pricing-table h3 {
    font-size: 2em;
  }
}

@media (max-width: 576px) {
  .reservations-page .reservations-title {
    font-size: 2em;
  }

  .reservations-page .reservations-description {
    font-size: 1.1em;
  }

  .reservations-page .simulator h2 {
    font-size: 1.2em;
  }

  .reservations-page .datepicker {
    font-size: 0.8em;
  }

  .reservations-page .info-section h3,
  .reservations-page .pricing-table h3,
  .reservations-page .promotions-section h3 {
    font-size: 1.6em;
  }

  .reservations-page .info-section p,
  .reservations-page .promotions-section p {
    font-size: 1em;
  }

  .reservations-page .checkout-button {
    font-size: 1em;
  }

  .reservations-page .decorative-golfball {
    width: 50px;
    height: 50px;
  }

  .reservations-page .image-section img {
    width: 100%;
    height: auto;
  }

  .reservations-page .pricing-table th,
  .reservations-page .pricing-table td {
    padding: 8px;
    font-size: 0.8em;
  }

  .reservations-page .pricing-table h3 {
    font-size: 1.5em;
  }
}
