@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Playfair+Display:wght@400;700&display=swap');

body.about-page {
  margin: 0;
  padding: 0;
  font-family: 'Playfair Display', serif;
  background-color: #2C2C2C;
  color: #F5F5DC;
  min-height: 100vh;
}
/* Add this to the About Page CSS if necessary */
.about-page .navbar-logo img {
    height: 60px; /* Control logo height */
    width: auto;
    object-fit: contain;
  }
  
.about-page .about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 50px auto;
  padding: 20px;
  background-color: #444444;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.about-page-title {
  text-align: center;
  font-size: 4em;
  margin: 20px auto;
  color: #556B2F;
  padding: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  width: fit-content;
  margin-bottom: 30px;
  color: #F5F5DC;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  border-bottom: 2px solid #556B2F;
}

.about-page .media-container {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Align images with the center of the video */
  width: 100%;
  margin-bottom: 40px;
  position: relative;
}

.about-page .media-left,
.about-page .media-right,
.about-page .media-center {
  background-color: #3B3B3B;
  border: 1px solid #CCC;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.about-page .media-left,
.about-page .media-right {
  width: 30%;
  align-self: center; /* Align images with the middle of the video */
}

.about-page .media-center {
  width: 35%;
  margin: 0 20px;
}

.about-page img,
.about-page video {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.about-page .text-block {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 40px;
  
}

.about-page .text-left,
.about-page .text-right {
  width: 48%;
  background-color: #3B3B3B;
  border: 1px solid #CCC;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
 
}

.about-page .text-left h2,
.about-page .text-right h2 {
  font-size: 1.8em;
  margin-bottom: 20px;
}

.about-page .text-left p,
.about-page .text-right p {
  font-size: 1.1em;
  line-height: 1.6;
}

.about-page .pics {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.about-page .pic {
  width: 80px;
  height: 80px;
  background-color: #444444;
  border: 1px solid #CCC;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #F5F5DC;
}

@media (min-width: 992px) {
  .text-box {
    min-height: 350px; /* Adjust this value as per your design */
  }
}

@media (max-width: 992px) {
  .about-page .media-container {
    flex-direction: column;
    align-items: center;
  }

  .about-page .media-left,
  .about-page .media-right,
  .about-page .media-center {
    width: 80%;
    margin-bottom: 20px;
  }

  .about-page .media-center {
    width: 100%;
  }

  .about-page .text-block {
    flex-direction: column;
    align-items: center;
  }

  .about-page .text-left,
  .about-page .text-right {
    width: 100%;
    margin-bottom: 20px;
  }
}
@media (min-width: 992px) {
  .text-left, .text-right {
    min-height: 400px; /* Ensure equal height in large screens */
  }
}