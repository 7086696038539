.admin-login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #333333;
  color: #EDEDED;
}

.admin-login-container h1 {
  font-size: 3em;
  margin-bottom: 20px;
  color: #F5F5DC;
}

.admin-login-container .form-group {
  margin-bottom: 15px;
}

.admin-login-container .form-group label {
  display: block;
  margin-bottom: 5px;
  color: #F5F5DC;
}

.admin-login-container .form-group input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #6B8E23;
  width: 200px;
}

.admin-login-container button {
  padding: 10px 20px;
  font-size: 1em;
  background-color: #6B8E23;
  color: #F5F5DC;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin-top: 10px;
  width: 100px;
}

.admin-login-container button:hover {
  background-color: #556B2F;
  transform: scale(1.05);
}
