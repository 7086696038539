@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Playfair+Display:wght@400;700&display=swap');

/* Ensure the body has scrollable content */
body {
  margin: 0;
  padding: 0;
  overflow-y: auto;
}

/* Background for Menu Page */
.menu-background {
  width: 100vw;
  min-height: 100vh;
  background-color: #2C2C2C; /* Fallback color */
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.menu-background.lazyloaded {
  background-image: url('https://swingtt.s3.us-east-2.amazonaws.com/Capture-11.jpg'); /* Optimized background image */
  animation: fadeIn 1.5s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Menu Page Styles */
.menu-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #F8F8FF;
  font-family: 'Playfair Display', serif;
  padding: 40px;
  width: 100%;
  min-height: 100vh;
  padding-top: 80px; /* Adjust for fixed navbar */
  animation: fadeIn 1.5s;
}

.menu-title {
  text-align: center;
  font-size: 4em;
  margin: 20px auto;
  color: #556B2F;
  padding: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  width: fit-content;
  margin-bottom: 30px;
  color: #F5F5DC;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  border-bottom: 2px solid #556B2F;
}

/* Row for displaying the two menus side by side */
.menu-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.menu-column {
  width: 48%; /* Set both columns to be 48% of the row to make them even */
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Menu Section Styles */
.menu-subtitle {
  font-size: 2.5em;
  color: #F5F5DC;
  margin-bottom: 20px;
  text-align: center;
}

/* Menu Image Container */
.menu-image-container {
  width: 100%;
  text-align: center;
}

.menu-image {
  width: 100%;
  height: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}



/* Responsive Menu Page */
@media (max-width: 768px) {
  .menu-title {
    font-size: 2.5em;
  }

  .menu-subtitle {
    font-size: 2.2em;
  }

  .menu-row {
    flex-direction: column; /* Stack the two menus on smaller screens */
  }

  .menu-column {
    width: 100%;
    margin-bottom: 30px; /* Add space between stacked menus */
  }
}

@media (max-width: 480px) {
  .menu-title {
    font-size: 2em;
  }

  .menu-subtitle {
    font-size: 2em;
  }

  .menu-image-container {
    width: 95%;
  }
}
