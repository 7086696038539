@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap');

body {
  margin: 0;
  padding: 0;
}

.navbar {
  background-color: #2C2C2C;
  color: #F5F5DC;
  padding: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  height: 80px;
  display: flex;
  align-items: center;
  font-family: 'Montserrat', sans-serif; /* Fixed font-family for navbar */
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;
}

.navbar-logo {
  padding-left: 20px;
}

.navbar-logo a {
  text-decoration: none;
}

.navbar-logo-img {
  height: 60px;
  width: auto;
}
/* Global navbar CSS remains as is */
/* Ensure this is in the Navbar CSS */
.navbar-logo img {
  height: 60px; /* Explicit control */
  width: auto;
  object-fit: contain; /* Prevent scaling issues */
}


.navbar-menu {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

.navbar-item {
  margin: 0 15px;
  position: relative;
}

.navbar-item:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 50%;
  right: -15px;
  transform: translateY(-50%);
  height: 70%;
  width: 1px;
  background-color: #F5F5DC;
}

.navbar-link {
  font-size: 1.2em;
  color: #F5F5DC;
  text-decoration: none;
  transition: color 0.3s;
}

.navbar-link:hover {
  color: #556B2F;
}

.navbar-toggle {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  width: 30px;
  cursor: pointer;
}

.navbar-toggle-icon {
  width: 100%;
  height: 3px;
  background-color: #F5F5DC;
  transition: all 0.3s ease;
}

@media (max-width: 768px) {
  .navbar-menu {
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
    background-color: #2C2C2C;
    flex-direction: column;
    align-items: center;
    display: none;
    padding: 20px 0;
  }

  .navbar-menu.active {
    display: flex;
  }

  .navbar-item {
    margin: 10px 0;
  }

  .navbar-item:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 150%;
    left: 50%;
    transform: translateX(-50%);
    width: 70%;
    height: 1px;
    background-color: #F5F5DC;
  }

  .navbar-toggle {
    display: flex;
  }
}

@media (max-width: 576px) {
  .navbar-link {
    font-size: 0.8em;
  }

  .navbar-item {
    margin: 10px 0;
  }

  .navbar-item:not(:last-child)::after {
    width: 60%;
  }

  .navbar-logo {
    padding-left: 15px;
  }

  .navbar-logo-img {
    height: 50px;
  }
}
