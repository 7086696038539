@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

.landing-container {
  font-family: 'Playfair Display', serif;
  color: #F5F5DC;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: none; /* Ensure no background color */
  position: relative;
  z-index: 0;
}

.header video {
  width: 100%;
  height: auto;
  max-height: 100vh;
  object-fit: cover;
  z-index: -1;
}

.header::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.about-section {
  padding: 60px 20px;
  text-align: center;
  background: linear-gradient(135deg, #556B2F, #2C2C2C);
  color: #F5F5DC;
}

.features-section,
.contact-section {
  padding: 60px 20px;
  text-align: center;
  background-color: #444444;
  color: #F5F5DC;
}

.about-section h2,
.features-section h2,
.contact-section h2 {
  font-size: 2.5em;
  margin-bottom: 0.5em;
  animation: fadeInDown 1s ease-in-out;
}

.about-section p,
.contact-section p {
  font-size: 1.2em;
  margin-bottom: 1.5em;
  animation: fadeInUp 1s ease-in-out;
}

.features-grid {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  animation: fadeInUp 1s ease-in-out;
}

.feature {
  flex: 1 1 30%;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
  background: linear-gradient(135deg, #556B2F, #2C2C2C);
  color: #F5F5DC;
}

.feature:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
}

.feature h3 {
  font-size: 1.8em;
  margin-bottom: 0.5em;
}

.feature p {
  font-size: 1.1em;
}

.contact-section a {
  color: #ff5733;
  text-decoration: none;
}

.contact-section a:hover {
  text-decoration: underline;
}


/* Book Now Section */
.book-now-section {
  text-align: center;
  padding: 40px 20px;
  background-color: #2C2C2C;
  color: #F5F5DC;
}

.book-now-section h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.book-now-section .book-now-image {
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
}

.date-picker-container {
  margin: 20px auto;
  display: flex;
  justify-content: center;
}

.custom-datepicker {
  font-size: 1.5em;
  padding: 15px;
  border-radius: 5px;
  border: 2px solid #556B2F;
  width: 100%;
  max-width: 300px;
  background-color: #F5F5DC;
  color: #2C2C2C;
}

/* Animations */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}