.admin-dashboard-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: #2C2C2C;
    color: #F5F5DC;
    min-height: 100vh;
    flex-wrap: wrap;
  }
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup {
    background: #2C2C2C;
    padding: 40px;
    border-radius: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.7);
    width: 80%;
    max-width: 600px;
    text-align: left;
  }
  
  .popup h2 {
    font-size: 2em;
    color: #F5F5DC;
    margin-bottom: 20px;
  }
  
  .popup p {
    font-size: 1.2em;
    color: #F5F5DC;
    margin-bottom: 20px;
    max-height: 300px;
    overflow-y: auto;
    padding: 10px;
    line-height: 1.6;
    text-align: justify;
  }
  
  .popup .agree-section {
    margin-bottom: 20px;
  }
  
  .popup .buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  
  .popup .continue-button,
  .popup .close-button {
    padding: 10px 20px;
    font-size: 1.2em;
    color: #F5F5DC;
    background-color: #556B2F;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .popup .continue-button:disabled {
    background-color: #888888;
  }
  
  .popup .continue-button:hover:enabled,
  .popup .close-button:hover {
    background-color: #6B8E23;
  }
  
  .left-section {
    width: 65%;
    min-width: 300px;
    margin-bottom: 20px;
  }
  
  .right-section {
    width: 30%;
    background-color: #444444;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    min-width: 250px;
  }
  
  .bookings-container {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    gap: 20px; /* Add spacing between the columns */
  }
  
  .simulator-column {
    width: 48%; /* Ensures each column takes up half the width */
  }
  
  .simulator-titles {
    margin-bottom: 10px;
    text-align: center;
  }
  
  .time-slot {
    margin-bottom: 10px;
  }
  
  .available {
    background-color: #6B8E23;
    color: #F5F5DC;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
  }
  
  .unavailable {
    background-color: #9b2e2e;
    color: #444;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    pointer-events: none;
  }
  
  
  .selected {
    border: 2px solid #F5F5DC;
    box-shadow: 0 0 10px #F5F5DC;
    transition: all 0.3s ease;
    color: #F5F5DC;
  }
  
  
  .custom-input {
    background-color: #444444;
    color: #F5F5DC;
    padding: 15px;
    font-size: 1.2em;
    border: none;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 15px;
  }
  
  .custom-datepicker {
    background-color: #444444;
    color: #F5F5DC;
    padding: 15px;
    font-size: 1.2em;
    border: none;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 15px;
  }
  
  .create-btn {
    background-color: #6B8E23;
    color: #F5F5DC;
    padding: 15px;
    font-size: 1.2em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    margin-bottom: 15px;
  }
  
  .terms-btn {
    background-color: #6B8E23;
    color: #F5F5DC;
    padding: 10px;
    font-size: 1.2em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
  }
  
  .terms-popup {
    background-color: #2C2C2C;
    color: #F5F5DC;
    padding: 10px;
    border-radius: 5px;
    margin-top: 15px;
  }
  
  .calendar-container {
    margin-bottom: 30px;
    width: 100%;
   
  }
  
  .react-calendar {
    background-color: #444444;
    color: #F5F5DC;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    width: 100%;
    max-width: 100%; 
  }
  
  .react-calendar__tile {
    background-color: #444444;
    color: #F5F5DC;
    
    border-radius: 5px;
  }
  
  .react-calendar__tile--active {
    background-color: #6B8E23 !important;
    color: #F5F5DC !important;
  }
  
  .react-calendar__tile--now {
    background-color: rgba(107, 142, 35, 0.5) !important;
    color: #F5F5DC !important;
  }
  
  .react-calendar__tile--hover {
    background-color: #556B2F !important;
    color: #F5F5DC !important;
  }
  
  .time-slot p {
    margin: 0;
    font-size: 1.1em;
  }
  

  /* Pricing Table Styles */
.pricing-table {
  margin: 40px 0;
  text-align: left;
  padding: 20px;
  background-color: #2C2C2C;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.pricing-table h3 {
  font-size: 2.5em;
  color: #F5F5DC;
  margin-bottom: 15px;
}

.pricing-table table {
  width: 100%;
  border-collapse: collapse;
  color: #F5F5DC;
}

.pricing-table th, .pricing-table td {
  border: 1px solid #556B2F;
  padding: 15px;
  text-align: left;
}

.pricing-table th {
  background-color: #3B3B3B;
}

.pricing-table td {
  background-color: #2C2C2C;
}

  @media screen and (max-width: 992px) {
    .left-section {
      width: 100%;
    }
  
    .right-section {
      width: 100%;
      margin-top: 20px;
    }
  }

  /* Add this to booking.css */
.selected {
    border: 2px solid #F5F5DC;
    box-shadow: 0 0 10px #F5F5DC;
    transition: all 0.3s ease;
    color: #F5F5DC;
}

  
  @media screen and (max-width: 576px) {
    .custom-input {
      font-size: 1em;
    }
  
    .create-btn {
      font-size: 1em;
    }
  }
