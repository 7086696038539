@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Playfair+Display:wght@400;700&display=swap');

body.contact-page {
  margin: 0;
  padding: 0;
  background: rgba(18, 18, 18, 0.6);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: overlay;
  color: #F8F8FF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: 'Playfair Display', serif;
}

.contact-page-container {
  padding: 40px;
  max-width: 90%;
  margin: 60px auto;
  background-color: rgba(44, 44, 44, 0.85); /* Charcoal */
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  text-align: center;
  animation: fadeIn 1.5s;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.contact-title {
  text-align: center;
  font-size: 4em;
  margin: 20px auto;
  color: #556B2F;
  padding: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  width: fit-content;
  margin-bottom: 30px;
  color: #F5F5DC;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  border-bottom: 2px solid #556B2F;
}

.contact-info {
  display: flex;
  justify-content: space-around;
  margin: 30px 0;
  text-align: left;
  flex-wrap: wrap;
  gap: 20px; /* Add this line to create padding between the info items */
}

.info-item-link {
  text-decoration: none;
  flex: 1;
  min-width: 200px;
}

.info-item {
  text-align: center;
  color: #F5F5DC; /* Beige */
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer; /* Add cursor pointer to show it is clickable */
}

.info-item:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.8);
}

.contact-form {
  background-color: #3B3B3B; /* Darker Charcoal */
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 5px;
  font-size: 1.2em;
  color: #F5F5DC; /* Beige */
}

.form-group input,
.form-group textarea {
  padding: 10px;
  border: 2px solid #556B2F; /* Dark Green */
  border-radius: 5px;
  font-size: 1em;
  background-color: #F5F5DC; /* Beige */
  color: #2C2C2C; /* Sophisticated dark background */
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #6B8E23; /* Slightly lighter green */
}

.contact-button {
  padding: 10px 20px;
  font-size: 1.5em;
  color: #F5F5DC; /* Beige */
  background-color: #556B2F; /* Dark Green */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s;
  font-family: 'Playfair Display', serif;
}

.contact-button:hover {
  background-color: #6B8E23; /* Slightly lighter green */
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

/* Responsive Design */
@media (max-width: 1200px) {
  .contact-title {
    font-size: 3em;
  }

  .contact-info {
    font-size: 1.1em;
  }

  .form-group label {
    font-size: 1.1em;
  }

  .contact-button {
    font-size: 1.3em;
  }
}

@media (max-width: 992px) {
  .contact-title {
    font-size: 2.5em;
  }

  .contact-info {
    font-size: 1em;
    flex-direction: column;
    gap: 10px;
  }

  .form-group label {
    font-size: 1em;
  }

  .contact-button {
    font-size: 1.2em;
  }
}

@media (max-width: 768px) {
  .contact-title {
    font-size: 2em;
  }

  .form-group label {
    font-size: 0.9em;
  }

  .contact-button {
    font-size: 1.1em;
  }
}

@media (max-width: 576px) {
  .contact-title {
    font-size: 1.8em;
  }

  .contact-info {
    font-size: 0.9em;
  }

  .form-group label {
    font-size: 0.8em;
  }

  .contact-button {
    font-size: 1em;
  }

  .contact-page-container {
    padding: 20px;
    margin: 30px auto;
  }
}
